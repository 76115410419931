import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const Projects = () => {
  const data = useStaticQuery(graphql`
    query ProjectsQuery {
      Project1: file(relativePath: { eq: "project-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1088) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Project2: file(relativePath: { eq: "project-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1088) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Project3: file(relativePath: { eq: "project-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1088) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div
      id="our-projects"
      className="max-w-6xl mx-auto px-8 py-12 text-xl w-full"
    >
      <h2
        id="project-1"
        className="font-bold leading-none mb-6 text-4xl text-pink-600 uppercase"
      >
        Birramal Road - <span className="inline-block">New build</span>
      </h2>
      <div className="mb-12">
        <ul className="list-disc ml-6">
          <li>Roof</li>
          <li>
            Supply and install:
            <ul className="list-disc ml-6">
              <li>Top hat battens</li>
              <li>55mm Anticon insulation blanket</li>
              <li>Colorbond 0.42bmt corrugated roof sheets in Surfmist</li>
              <li>Colorbond valleys and ridges</li>
              <li>Colorbond fascia</li>
              <li>Colorbond half round flat back and half round gutter</li>
              <li>Gable roofs with custom work</li>
              <li>Aluminum leaf guard to all gutters and valleys</li>
              <li>Downpipes pvc with stainless steel stand off brackets</li>
            </ul>
          </li>
        </ul>
        <p className="mt-4">
          <em>Credit: Built by Hamilton Projects</em>
        </p>
      </div>
      <Image
        className="mb-24"
        style={{ height: `100%` }}
        fluid={data.Project1.childImageSharp.fluid}
      />
      <h2
        id="project-2"
        className="font-bold leading-none mb-6 text-4xl text-pink-600 uppercase"
      >
        Hillview Drive - <span className="inline-block">Re-roof</span>
      </h2>
      <div className="mb-12">
        <ul className="list-disc ml-6">
          <li>
            Remove existing ridges, roof sheets, barges, skylights, gutters
          </li>
          <li>
            Supply and install:
            <ul className="list-disc ml-6">
              <li>Metal top hat battens</li>
              <li>55mm Anticon insulation blanket</li>
              <li>Colorbond 0.42bmt corrugated roof sheets in Basalt</li>
              <li>Colorbond ridge capping</li>
              <li>Colorbond valleys</li>
              <li>Colorbond barge cappings</li>
              <li>Colorbond fascia</li>
              <li>Colorbond slotted quad gutters</li>
              <li>Aluminium leaf guard to all gutters and valleys</li>
              <li>Custom flashing work and skylights</li>
            </ul>
          </li>
        </ul>
      </div>
      <Image
        className="mb-24"
        style={{ height: `100%` }}
        fluid={data.Project2.childImageSharp.fluid}
      />
      <h2
        id="project-3"
        className="font-bold leading-none mb-6 text-4xl text-pink-600 uppercase"
      >
        Gormans Lane - <span className="inline-block">New build</span>
      </h2>
      <div className="mb-12">
        <ul className="list-disc ml-6">
          <li>Roof and wall cladding</li>
          <li>
            Supply and install:
            <ul className="list-disc ml-6">
              <li>55mm Anticon insulation blanket</li>
              <li>Colorbond 0.42bmt corrugated roof sheets in Monument Matt</li>
              <li>Colorbond 150 half round slotted Monument Matt gutters</li>
              <li>Colorbond Downpipes</li>
              <li>Colorbond Monument Matt fascia</li>
              <li>Custom Colorbond Monument Matt flashings and barges</li>
              <li>
                Colorbond 0.42bmt corrugated sheets in Monument Matt for Wall
                Cladding and Sarking
              </li>
              <li>
                Colorbond Monument Matt custom flashings work and window
                flashings
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Image
        style={{ height: `100%` }}
        fluid={data.Project3.childImageSharp.fluid}
      />
    </div>
  );
};

export default Projects;
