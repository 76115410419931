import React from 'react';

const CallToActionProjectsHero = () => {
  return (
    <div className="sm:flex -mb-12 relative up-50">
      <p className="cta-left bg-pink-600 p-8 font-bold leading-tight text-white text-xl md:text-2xl lg:text-3xl uppercase">
        <span className="inline-block mr-8 sm:mr-24 my-8 whitespace-no-wrap">
          Projects
        </span>
      </p>
    </div>
  );
};

export default CallToActionProjectsHero;
