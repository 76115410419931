import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProjectsHero from '../components/ProjectsHero';
import CallToActionProjectsHero from '../components/CallToAction/ProjectsHero';
import Projects from '../components/Projects';
import Instagram from '../components/Instagram';
import CallToActionContact from '../components/CallToAction/Contact';
import Contact from '../components/Contact';

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <ProjectsHero />
    <CallToActionProjectsHero />
    <Projects />
    <Instagram />
    <CallToActionContact primary="pink-600" />
    <Contact primary="pink-600" secondary="pink-500" />
  </Layout>
);

export default ProjectsPage;
