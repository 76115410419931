import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const ProjectsHero = () => {
  const data = useStaticQuery(graphql`
    query ProjectsHeroQuery {
      file(relativePath: { eq: "projects-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <header id="hero" className="font-bold relative text-white uppercase">
      <Image
        style={{ minHeight: `50vh`, height: `30rem` }}
        fluid={data.file.childImageSharp.fluid}
        loading="eager"
      />
    </header>
  );
};

export default ProjectsHero;
