import PropTypes from 'prop-types';
import React from 'react';

import { useInstagram, useLazyLoad } from '../hooks';

function Post({ post }) {
  const { ref, imgRef, isImgLoaded, handleImgLoaded, Spinner } = useLazyLoad();
  return (
    <a
      ref={ref}
      href={post.url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex w-1/2 sm:w-1/3 md:w-1/4"
    >
      <div className="relative w-full h-0 m-2 overflow-hidden aspect-ratio-square">
        <div>
          <img
            ref={imgRef}
            onLoad={handleImgLoaded}
            data-src={post.src}
            srcSet={post.srcSet.toString()}
            alt={post.caption}
            className="absolute inset-0 object-contain w-full h-full"
          />
          <div className="absolute inset-0 flex w-full h-full p-4 overflow-y-auto text-white transition duration-150 ease-in-out opacity-0 bg-transparent-black hover:opacity-100">
            {post.caption}
            <br />
            &nbsp;
          </div>
          {!isImgLoaded && <Spinner />}
        </div>
      </div>
    </a>
  );
}

Post.propTypes = {
  post: PropTypes.shape({
    caption: PropTypes.any,
    src: PropTypes.any,
    srcSet: PropTypes.array,
    url: PropTypes.any,
  }),
};

const Instagram = () => {
  const posts = useInstagram();
  const { Spinner } = useLazyLoad();
  return (
    <div className="w-full max-w-6xl px-8 py-12 mx-auto">
      <h2 className="mb-6 text-4xl font-bold leading-none text-pink-600 uppercase">
        See More Projects
      </h2>
      <div className="flex flex-wrap -mx-2">
        {posts.length
          ? posts.map((post) => <Post key={post.id} post={post} />)
          : Array(12)
              .fill('')
              .map((_, index) => (
                <div key={index} className="flex w-1/2 sm:w-1/3 md:w-1/4">
                  <div className="relative w-full h-0 m-2 overflow-hidden aspect-ratio-square">
                    <Spinner />
                  </div>
                </div>
              ))}
      </div>
    </div>
  );
};

export default Instagram;
